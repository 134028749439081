@keyframes slideRight{

    from{
        transform: translateX(0px);

    }
    to{
        transform: translateX(15px);

    }
}

.slideRightAnimated:hover{
    animation: slideRight 0.3s ease-in infinite;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;


}


.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}


.orange{
    color: #EA7428;
}



.showOnHover{
    display:none;
}

@keyframes enlarge{

    from{
        transform: scale(1);
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;

    }
    to{
        transform: scale(1.2);
        box-shadow: 0 .8rem 1.2rem rgba(0,0,0,.25) !important;

    }
}


@media (hover: hover) and (pointer: fine) {
    .enlargeHover:hover{
        animation: enlarge 0.3s ease-in infinite;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        z-index:100;
        cursor: pointer;
        box-shadow: 0 .5rem 1.0rem rgba(0,0,0,.15) !important;
    }
    .enlargeHover:hover .showOnHover{
        display:block;
    }

}
